import React, { useState, useEffect } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useWindowSize } from "../../utils/useWindowSize";

const Filters = ({ currentProduct, changeProduct, filterName, isCustom, onValueChange }) => {
  const size = useWindowSize();

  const [mobileFilterExpand, setMobileFilterExpand] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const excludedProducts = ['Xtreme Decking', 'Cladding'];
  const [inputValue, setInputValue] = useState(currentProduct.productName);

  const filterStatus = (product) => {
    let status;
    let str = currentProduct.productName;
    if(str.includes(" Custom")){
      str = str.substring(0, str.indexOf(" Custom")); 
    }
    if (str === product) status = "selected-tab";
    return status;
  };

  const handleFilterChange = (event) => {
    if(isCustom && !excludedProducts.includes(event.target.innerText)){
      changeProduct(event.target.innerText + ' Custom Colours');  
    }else{
      changeProduct(event.target.innerText);
    }
    const value = event.target.innerText;
    setInputValue(value);
    onValueChange(value);
    console.log(inputValue);
  };

  let products;

  if(filterName === "decking"){
    products = [
      "Xtreme Decking",
      "Original Decking",
      "EcoPro Decking",
    ]
  }else if(filterName === "original"){
    products = [
      "Original Decking",
      "EcoPro Decking"
    ]
  }else if(filterName === "screenfence"){
    products = [
      "Screen Fencing",
      "Xtreme Screen Fencing"
    ]
  }else{
    products = [
      "Xtreme Decking",
      "Original Decking",
      "EcoPro Decking",
      "Cladding",
      "Xtreme Screen Fencing",
      "Screen Fencing",
    ];
  }

  useEffect(() => {
    if(filterName === "original" && isSet === false){
      changeProduct('Original Decking');
      setIsSet(true);
    }else if(filterName === "screenfence" && isSet === false){
      changeProduct('Screen Fencing');
      setIsSet(true);
    }
  }, [filterName, isSet,changeProduct]);

  return (
    <>
      {size.width <= 768 ? (
        <div className="d-flex filter-mobile-dropdown">
          <button className="d-flex filter-mobile-btn"
            onClick={(e) => {
              e.preventDefault();
              setMobileFilterExpand(!mobileFilterExpand);
            }}
          >
            <span className="filter-mobile-btn-text">
              {currentProduct.productName
                ? currentProduct.productName
                : "Select Product"}
            </span>
            <>
              {mobileFilterExpand ? <HiChevronUp /> : <HiChevronDown />}
            </>
          </button>
          <ul className="filter-mobile-menu" style={{display: mobileFilterExpand ? 'block' : 'none'}}>
            {products.map((product, index) => (
                <li className="d-flex filter-mobile-item"
                  key={index}
                  onClick={(event) => {
                    handleFilterChange(event);
                    setMobileFilterExpand(!mobileFilterExpand);
                  }}
                >
                  {product}
                </li>
              ))
            }
          </ul>
        </div>
      ) : (
        <nav className="d-flex filter-nav">
          <div className="d-flex filter-item-container">
            <ul className="d-flex">
              {products.map((product, index) => (
                  <li
                    className={`d-flex filter-item ${filterStatus(product)}`}
                    key={product[index]}
                  >
                    <a
                      aria-current="page"
                      href="#!"
                      onClick={(event) => handleFilterChange(event)}
                    >
                      <h3>{products[index]}</h3>
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
        </nav>
      )}
    </>
  );
};

export default Filters;
