import React, {useState, useEffect} from 'react';
import ColourExplorer from './components/color-explorer/ColourExplorer';
import DeckingCalculator from './components/decking-calculator/DeckingEstimator';

function App({  appName, productName, filterName, activeFilterP }) {
    const [prodInfoLink, setProdInfoLink] = useState('https://www.futurewood.com.au/decking/xtreme/')
    const [prodParam, setProdParam] = useState('');
    const [prod, setProd] = useState('Xtreme');
    const [filetrParam, setFiletrParam] = useState('');
    const [appNameMain, setAppName] = useState('ce');
    const [activeFilter, setActiveFilter] = useState('');

    const handleValueChange = (value) => {
        if(value === "Xtreme Decking"){
            setProdInfoLink('https://www.futurewood.com.au/decking/xtreme/');
            setProd('Xtreme');
        }else if(value === "Original Decking"){
            setProdInfoLink('https://www.futurewood.com.au/decking/original/');
            setProd('Original');
        }else if(value === "EcoPro Decking"){
            setProdInfoLink('https://www.futurewood.com.au/decking/original/');
            setProd('Eco Pro');
        }else if(value === "Cladding"){
            setProdInfoLink('https://www.futurewood.com.au/cladding/');
        }else if(value === "Screen Fencing"){
            setProdInfoLink('https://www.futurewood.com.au/screen-fencing/');
        }else{
            setProdInfoLink('https://www.futurewood.com.au/screen-fencing/');
        }
    }

    useEffect(() => {
        const getParamValue = (paramName) => {
            var url = (typeof window !== 'undefined' && window.location.search.substring(1)); //get rid of "?" in querystring
            var qArray = url.split("&"); //get key-value pairs
            for (var i = 0; i < qArray.length; i++) {
              var pArr = qArray[i].split("="); //split key and value
              if (pArr[0] === paramName) return pArr[1]; //return value
            }
        };

        let prdParamVal = getParamValue("product_name") || productName;
        let filterParamVal = getParamValue("filter") || filterName;
        let appNameVal = getParamValue("app") || appName;
        let activeFilterVal = getParamValue("active_filter") || activeFilterP;

        if (typeof prdParamVal !== "undefined") {
            let prdParamValActual = prdParamVal.replace("-", " ");
            setProdParam(prdParamValActual);
        }
        if (typeof filterParamVal !== "undefined") {
            setFiletrParam(filterParamVal);
        }
        if (typeof appNameVal !== "undefined") {
            setAppName(appNameVal);
        }
        if (typeof activeFilterVal !== "undefined") {
            setActiveFilter(activeFilterVal);
        }
    },[productName, filterName, appName, activeFilterP]);

    useEffect(() => {
        function sendHeight(bodyHeight) {
            window.parent.postMessage({ bodyHeight }, '*');
        }

        function detectHeightChange() {
            var bodyHeight = document.body.scrollHeight;
            sendHeight(bodyHeight);
        }
        window.addEventListener('load', () => {
            setTimeout(detectHeightChange, 100);
        });
        window.addEventListener('resize', detectHeightChange);

        document.body.addEventListener('click', detectHeightChange);

        var observer = new MutationObserver(detectHeightChange);
        observer.observe(document.body, { attributes: true, childList: true, subtree: true });

        return () => {
          window.removeEventListener('resize', detectHeightChange);
        };
    }, []);

    return (
        <>
            {appNameMain === 'ce' ?
                <>
                    <ColourExplorer prdName={prodParam} filterName={filetrParam} onValueChange={handleValueChange} activeFilter={activeFilter} />
                    <div className="colour-explorer-footer">
                        <a rel="nofollow noreferrer" className={(prodParam === "" && filetrParam === 'decking') ? 'nex_primary_btn m-0' : 'nex_secondary_btn m-0'} href={(prodParam === "" && filetrParam === '') ? prodInfoLink : (prodParam === "" && filetrParam === 'decking') ? prodInfoLink : (prodParam === "" && filetrParam === 'original') ? 'https://www.futurewood.com.au/decking/original/#available_sizes' : (prodParam === 'Xtreme Decking') ? 'https://www.futurewood.com.au/decking/xtreme/#available_sizes' : (prodParam === 'Cladding') ? 'https://www.futurewood.com.au/cladding/#available_sizes' : (prodParam === 'Screen Fencing') ? 'https://www.futurewood.com.au/screen-fencing/#available_sizes' : '#available_sizes'} target="_parent">{(prodParam === "" && filetrParam === 'decking') ? 'Explore ' + prod : 'See Product Information'}</a>
                        <a rel="nofollow" className={(prodParam === "" && filetrParam === 'decking') ? 'nex_secondary_btn m-0' : 'nex_primary_btn m-0'} href={(prodParam === "" && filetrParam === 'decking') ? 'https://www.futurewood.com.au/tools-resources/' : (prodInfoLink === 'https://www.futurewood.com.au/cladding/' || prodInfoLink === 'https://www.futurewood.com.au/screen-fencing/' || prodParam === "Cladding" || prodParam === "Screen Fencing") ? 'https://www.futurewood.com.au/enquiries/' : "https://www.futurewood.com.au/decking/decking-calculator/#decking-calc"} target="_parent">{(prodParam === "" && filetrParam === 'decking') ? 'Tools & Resources' : (prodInfoLink === 'https://www.futurewood.com.au/cladding/' || prodInfoLink === 'https://www.futurewood.com.au/screen-fencing/' || prodParam === "Cladding" || prodParam === "Screen Fencing") ? 'Enquire Now' : 'Get An Estimate'}</a>
                    </div>
                </>
            : appNameMain === 'calc' ?
                <DeckingCalculator prdName="decking" />
            :
            <></>
            }
        </>
    )
}
export default App
