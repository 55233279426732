import React, { Component, Fragment } from 'react';
import prevArrow from '../../images/prev-arrow.png';
import nextArrow from '../../images/next-arrow.png';
import LeadFormHook from './LeadFormHook';
import { AnimatePresence, motion } from "framer-motion";
import yes from "../../images/yes.png"
import no from "../../images/no.png"
import home from "../../images/home.png"
import tools from "../../images/tools.png"
import architect from "../../images/architect.png"

class MultistepForm extends Component {
    constructor(props) {
        super(props);
        this.totalScore = 0;
    }

    state = {
        currentStep: 0,
        lastStep: 0,
        stepSkipped: 0,
        steps: [
            {
                q: "Can we quickly ask if you have used composite timber before?",
                options: ["Yes", "No"],
            },
            {
                q: "Are you an existing customer of Futurewood?",
                options: ["Yes", "No"],
            },
            {
                q: "Please tell us if you are a:",
                options: ["Home Owner", "Builder, Carpenter or Installer", "Project Manager or Architect"],
            },
            {
                q: "And finally, when is your project starting?",
                options: ["No Hurry, just getting prices", "Within 8 - 12 weeks", "Within 4 weeks", "Urgent or ASAP"],
            },
            {
                q: "Please provide your details",
            }
        ],
        answers: [],
        score: 0,
        previousScore: 0
    }

    answers = [ { name: "used_composite_before_", value: "" }, { name: "existing_fw_customer_", value: "" }, { name: "prospect_category", value: "" }, { name: "project_start_date_", value: "" } ];

    handleBack = ( event ) => {
        event.preventDefault();
        let currentStep = this.state.currentStep;
        if ( currentStep === 0 ) this.props.prevStep();
        else if (this.state.stepSkipped === 1 && currentStep === 2) currentStep -=2;
        else currentStep -= 1;
        this.setState( prevState => {
            return {
                currentStep,
                lastStep: prevState.currentStep,
                score: this.state.previousScore
            }
        } );
    }

    handleClick = (event) => {
        event.preventDefault();

        let currentStep, score, stepSkipped, btnText;

	if(event.target.innerText !== ''){
            btnText = event.target.innerText;
        }else{
            btnText = event.target.alt;
        }

        switch ( this.state.currentStep ) {
            case 0:
                if( btnText === "Yes"){
                    currentStep = 1;
                    score = 2;
		    this.totalScore += score;
                }else {
                    currentStep = 2
                    stepSkipped = 1;
                    score = 1;
		    this.totalScore += score;
                }
                this.answers[0].value = btnText;
                break;
            case 1:
                stepSkipped = this.state.stepSkipped;
                currentStep = this.state.currentStep + 1;
                this.answers[currentStep - 1].value = btnText;
                score = ( btnText === "Yes") ? 10 : 1;
		this.totalScore += score;
                break;
            case 2:
                stepSkipped = this.state.stepSkipped;
                currentStep = this.state.currentStep + 1;
                this.answers[currentStep - 1].value = btnText;
                score = (btnText === this.state.steps[2].options[0] ) ? 1 : 3;
		this.totalScore += score;
                break;
            case 3:
                stepSkipped = this.state.stepSkipped;
                currentStep = this.state.currentStep + 1;
                this.answers[currentStep - 1].value = btnText;
                score = ( btnText === this.state.steps[3].options[0] ) ? 1 : 3;
		this.totalScore += score;
                break;
            default:
                currentStep = this.state.currentStep;
        }
	this.props.updateFormScore( this.totalScore );
        this.setState( prevState => {
            return {
                currentStep,
                lastStep: prevState.currentStep,
                answers: this.answers,
                previousScore: prevState.score,
                score: prevState.score + score,
                stepSkipped
            }
        } );
    }

    handleNext = ( event ) => {
        event.preventDefault();
        let currentStep = this.state.currentStep;
        if ( currentStep === 4 ) return false;
        else if (this.state.stepSkipped === 1 && currentStep === 0 && this.answers[currentStep].value !== "") currentStep +=2;
        else if(this.answers[currentStep].value !== "") currentStep += 1;
        else (typeof window !== 'undefined' && window.alert("Please select an option"));
        this.setState( prevState => {
            return {
                currentStep,
                lastStep: prevState.currentStep,
                score: this.state.previousScore
            }
        } );
    }


    render() {

        let renderButtons = () => {
            if( this.state.currentStep !== 4 ) {
                return( this.state.steps[this.state.currentStep].options.map( ( option, index ) =>
                    <>
                    <button type="button" className="ga_btn_deck_calc" data-chosen= { (this.state.answers.length !== 0 && this.state.answers[this.state.currentStep].value === option) ? "selected" : "" } key={ index } data-question={this.state.steps[this.state.currentStep].q} onClick={ this.handleClick } >
                        { option }
                        {option === 'Yes' ? <img src={yes} alt="Yes" />
                        : option === 'No' ? <img src={no} alt="No" />
                        : option === 'Home Owner' ? <img src={home} alt="Home Owner" />
                        : option === 'Builder, Carpenter or Installer' ? <img src={tools} alt="Builder, Carpenter or Installer" />
                        : option === 'Project Manager or Architect' ? <img src={architect} alt="Project Manager or Architect" /> : ''}
                    </button>
                    </>
                ) );
            } else {
                return null;
            }

        }


        let renderFields  = () => {

            if( this.state.currentStep === 4 ) {
                return <LeadFormHook
                    nextStep = { this.props.nextStep }
                    submitData = { this.props.submitData }
                    answers = { this.state.answers }
                    answersAppState = { this.props.answers }
                    setStep = { this.setExternalCurrentStep }
                    currentStep = { this.props.currentStep }
                    lastStep = { this.props.lastStep }
                    /*score = { this.state.score }
                    updateFormScore = { this.props.updateFormScore }*/
                />
            } else {
                return null;
            }

        }

        if ( this.props.currentStep === 4 ) {
            return (
                <Fragment>
                    <motion.div initial="hidden" animate="visible" variants={{
                        hidden: {
                        x: (this.props.lastStep <= this.props.currentStep) ? 1000 : -1000,
                        opacity: 0
                        },
                        visible: {
                        x: 0,
                        opacity: 1,
                        transition: {
                        type: "easeInOut",
                        }
                        },
                    }}>
                        <h2 className='deck-calc-h2'>{this.state.currentStep < 4 ? 'You’re Almost There' : 'Your Instant Estimate Is Ready'}</h2>
                        <p className='deck-subheading'>{this.state.currentStep < 4 ? 'Thank you for the information so far! We have a few more quick questions to ask you.' : 'We’re ready to go! Please provide your contact details below and we will show your instant estimate.'}</p>
                        <AnimatePresence>
                            <motion.div
                                className='multistep-question'
                                key={ this.state.currentStep }
                                initial={{ x: (this.state.lastStep <= this.state.currentStep) ? 500 : -500, opacity: 0, display: "none" }}
                                animate={{ x: 0, opacity: 1, display: "block" }}
                                exit={{ x: (this.state.lastStep <= this.state.currentStep) ? 500 : -500, opacity: 0, display: "none" }}
                            >
                                {this.state.currentStep < 4 &&
                                    <div className='question'>{ this.state.steps[this.state.currentStep].q }</div>
                                }
                                {renderFields()}
                                <div className='answer-btn'>{ renderButtons() }</div>
                            </motion.div>
                        </AnimatePresence>
                        {this.state.currentStep < 4 &&
                            <div className='pagination two-btn'>
                                <button type="submit" onClick={ this.handleBack }><img className="prev-arrow" src={ prevArrow } alt="previous arrow" style={{width:'10px', height:'10px',fontWeight:'700'}}></img>Back</button>
                                <button type="button" onClick={ this.handleNext }>Next<img src={ nextArrow } alt="next arrow" style={{width:'10px', height:'10px',fontWeight:'700'}}></img></button>
                            </div>
                        }
                    </motion.div>
                </Fragment>
            );        
        }
        return null;
    }
}

export default MultistepForm;
