import React, { Component, Fragment } from 'react';
import Dimensions from './Dimensions';
import Materials from './Materials';
import Viewport from './Viewport';
import ProgressBar from './ProgressBar/ProgressBar.js';
import MultistepForm from './MultistepForm';
import Estimate from './Estimate';
import emailjs from 'emailjs-com';

class DeckingEstimator extends Component {
  state = {
    totalArea: 0,
    totalMatCost: 0,
    totalMaintCost: 0,
    totalAltCost: 0,
    totalMaintAltCost: 0,
    wastage: 5,
    isExpanded: false,
    currentStep: 1,
    lastStep: 1,
    formScore: 0,
    answers: [],
    profile: {
      name: '',
      price: '',
      description: ''
    },
    prodType: {
      name: 'decking',
      prodtypeName: ''
    },
    subprodType: {
      name: '',
      subprodtypeName : '',
      price: '',
      description: ''
    },
    dimensions: [{
      lengthArea: '',
      widthArea: '',
      id: 1
    }],
  }

  //Variables to store rates for products for basic calculator
  merbauAvg140 = 70;

  setWastage = (wastage) => {
    this.setState({
      wastage
    });
  }

  updateFormScore = (newScore) => {
   this.setState({
      formScore : newScore
    });
  }

  sendEmail = (answers) => {

    let email = answers.reduce((o, key) => ({ ...o, [key.name]: key.value}), {})

    if(answers[9].value <=3){
      emailjs.send('service_vc8ellv', 'template_xmmwdpq', email, 'user_fGwMSxEm2fm86CLZMDPTS') // Email to User
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    }else{
      emailjs.send('service_vc8ellv', 'template_qps21ej', email, 'user_fGwMSxEm2fm86CLZMDPTS') // Email to User
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    }

    emailjs.send('service_vc8ellv', 'template_qj2gvii', email, 'user_fGwMSxEm2fm86CLZMDPTS') // Email to Admin
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }

  // Hubspot Forms API
  submitData = (answers) => {

    this.setState({
      answers
    });

    if(answers[1].value !== "")
    {
      let existObj = {};
      existObj.name = "existing_cust";
      existObj.value = "Existing Customer: ";
      answers.push(existObj);
    }

    let areavar, wastevar;

    if((this.state.totalArea)%1 !== 0){
      areavar = parseFloat(this.state.totalArea + ((this.state.totalArea)*(this.state.wastage/100))).toFixed(2);
    } else{
      areavar = this.state.totalArea + (this.state.totalArea)*(this.state.wastage/100);
    }

    if(((this.state.totalArea)*(this.state.wastage/100))%1 !== 0){;
      wastevar = parseFloat((this.state.totalArea)*(this.state.wastage/100)).toFixed(2);
    } else{
      wastevar = (this.state.totalArea)*(this.state.wastage/100);
    }

    let scoreObj = {};
    scoreObj.name = "prospect_priority_score";
    scoreObj.value = this.state.formScore;
    answers.push(scoreObj);

    let areaObj = {};
    areaObj.name = "total_area";
    areaObj.value = areavar + "sqm";
    answers.push(areaObj);

    let materialObj = {};
    materialObj.name = "material_";
    materialObj.value = (this.state.profile.name).charAt(0).toUpperCase()+(this.state.profile.description).slice(1);
    answers.push(materialObj);

    let estAmtObj = {};
    estAmtObj.name = "estimated_amt";
    estAmtObj.value = "$ "+this.state.totalMatCost;
    answers.push(estAmtObj);

    let wastageObj = {};
    wastageObj.name = "wastage";
    wastageObj.value = wastevar + "sqm";
    answers.push(wastageObj);

    let totsavingObj = {};
    totsavingObj.name = "total_savings";
    totsavingObj.value = "$ "+Math.round(Math.abs((this.state.totalMatCost + (this.state.totalMaintCost * 10)) - (this.state.totalAltCost + (this.state.totalMaintAltCost * 10))));
    answers.push(totsavingObj);

    console.log( "Answers before sending email: ", answers );

    this.sendEmail(answers);

    const url = 'https://api.hsforms.com/submissions/v3/integration/submit/3986972/cd8d6a5e-b743-497f-bc11-6e2dacc62729';

    const timestamp = Date.now();

    console.log("Timestamp is:")
    console.log(timestamp);
    // post body data 
    var data = {
      "submittedAt": timestamp,
      "fields": answers,
    }

    // request options
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    // send POST request
    fetch(url, options)
      .then(res => {
        if (res.ok) {
          // Form submission successful
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'formSuccessfullySubmitted',
          });
          return res.json();
        } else {
          throw new Error('Form submission failed');
        }
      })
      //.then(res => res.json())
      .then(res => console.log(res));
  }


  sendEmailDiscussProj = (answers) => {

    let email = answers.reduce((o, key) => ({ ...o, [key.name]: key.value}), {})

    console.log(email);

    emailjs.send('service_vc8ellv', 'template_caijv1c', email, 'user_fGwMSxEm2fm86CLZMDPTS') // Email to Admin
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      }
    );
  }

  discussProject = (answers) => {
    this.setState({
      answers
    });
    this.sendEmailDiscussProj(answers);
  }

  totalAreaCalc = (dimensions) => {
    let area = [];
    let screeningHeight, screeningWidth;
    let totalMatCost, totalMaintCost, totalAltCost = 0;
    dimensions.forEach(dimension => {
      screeningWidth = (dimension.lengthArea)*1000;
      screeningHeight = (dimension.widthArea)*1000;
      area.push(dimension.lengthArea * dimension.widthArea);

    });
    let totalArea = area.reduce((total, num) => {
      return total + num;
    });

    this.setState({
      totalArea,
      dimensions,
      totalMatCost,
      totalMaintCost,
      totalAltCost,
      screeningHeight,
      screeningWidth
    });

  }

  calcTotalCostAdv = (profile) => {
    let wastage = this.state.wastage;
    let costArea, totalAltCost = 0;
    let S, T, B, C, A, TF, PL, NPL;
    let totalMaintCost = profile.name === "merbau" ? (this.state.totalArea) * 15 : 0;
    let totalMaintAltCost = profile.name === "merbau" ? 0 : (this.state.totalArea) * 15;
    let totalMerbauMaintCost = (this.state.totalArea) * 15;

    if(this.state.subprodType.name === "40x30"){
      A = 40;
    } else if(this.state.subprodType.name === "60x40"){
      A = 60;
    } else if(this.state.subprodType.name === "70x15"){
      A = 70;
    }

    switch (profile.name) {
      case "xtreme185":
      case "xtreme138":
      case "original138":
      case "ecopro138":
        costArea = this.state.totalArea < 43.2 ? (this.state.totalArea) * profile.priceSmall : (this.state.totalArea) * profile.priceLarge;
        totalAltCost = (this.state.totalArea) * this.merbauAvg140 + ((this.state.totalArea) * this.merbauAvg140 * (wastage / 100)) + totalMaintAltCost; // Adding first year of maintenance cost in material;
        break;
      case "horizontal":
        S = Math.ceil(this.state.screeningHeight/(A+wastage));
        if(this.state.screeningWidth <=5.4){          
          T = Math.floor(5400/this.state.screeningWidth);
          B = Math.ceil(S/T);
          C = B*(this.state.subprodType.price);
        } else{
          TF = Math.floor(this.state.screeningWidth/5400);
          PL = (this.state.screeningWidth)-(TF*5400);
          NPL = Math.floor(5400/PL);
          B = S+(TF*NPL);
          C = B*(this.state.subprodType.price);
        }
        break;
      case "vertical":
        S = Math.ceil(this.state.screeningWidth/(A+wastage));
        if(this.state.screeningWidth <=5.4){          
          T = Math.floor(5400/this.state.screeningHeight);
          B = Math.ceil(S/T);
          C = B*(this.state.subprodType.price);
        } else{
          TF = Math.floor(this.state.screeningHeight/5400);
          PL = (this.state.screeningHeight)-(TF*5400);
          NPL = Math.floor(5400/PL);
          B = Math.ceil(S/(TF+NPL));
          C = B*(this.state.subprodType.price);
        }
        break;
      default:
        (typeof window !== 'undefined' && window.alert('Sorry something went wrong. Please refresh your page and try again.'));
    }
    let wastageCost = costArea * (wastage / 100);
    let totalMatCost = Math.round(costArea + wastageCost + totalMaintCost); // Adding first year of maintenance cost in material;

    this.setState({
      totalMatCost,
      totalMaintCost,
      totalAltCost,
      totalMaintAltCost,
      totalMerbauMaintCost,
      C
    });
  }

  _nextProd = (prodType) => {
    let currentStep = this.state.currentStep;

    if (!prodType) prodType = this.state.prodType;
    
    if(prodType.name === 'decking'){
      currentStep += 2;
    }else{
      currentStep += 1;
    }

    this.setState(prevState => {
      return {
        currentStep,
        lastStep: prevState.currentStep,
        prodType
      }
    });
  }

  _nextsubProd = (subprodType) => {
    let currentStep = this.state.currentStep;

    if (!subprodType) subprodType = this.state.subprodType;
    
    currentStep += 1;
    
    this.setState(prevState => {
      return {
        currentStep,
        lastStep: prevState.currentStep,
        subprodType
      }
    });
  }

  _nextAdvanced = (profile) => {
    let currentStep = this.state.currentStep;

    if (!profile) profile = this.state.profile;
    
    currentStep += 1;

    this.setState(prevState => {
      return {
        currentStep,
        lastStep: prevState.currentStep,
        profile
      }
    });
  }

  _prevAdvanced = () => {
    let currentStep = this.state.currentStep;
    //currentStep = currentStep <= 1 ? 1 : currentStep - 1;

    if(this.state.prodType.name === 'decking' && this.state.currentStep === 3){
      currentStep = 1;
    }else{
      currentStep = currentStep - 1;
    }
    this.setState(prevState => {
      return {
        currentStep,
        lastStep: prevState.currentStep
      }
    });
  }

  _jumpStep = (stepValue) => {
    let currentStep;
    if (stepValue > 1 && (this.state.totalArea === 0 || this.state.totalArea === "")) {
      (typeof window !== 'undefined' && window.alert("Please enter legnth and width before proceeding."));
      currentStep = 1;
    } else if (stepValue > 2 && !this.state.profile.name) {
      (typeof window !== 'undefined' && window.alert("Please pick a material in step 2 to proceed further"));
      currentStep = 2;
    } else {
      currentStep = parseInt(stepValue);
    }

    let isExpanded = currentStep !== 4 ? false : this.handleExpandChange();

    this.setState(prevState => {
      return {
        currentStep,
        isExpanded,
        lastStep: prevState.currentStep
      }
    });
  }

  handleExpandChange = (isExpanded) => {

    this.setState({
      isExpanded
    });

    return isExpanded;
  }

  render() {
    const renderProgressBar = () => {
      if(this.state.currentStep >= 1)
      return ( 
        <ProgressBar jumpStep = {
          this._jumpStep
        }
        currentStep = {
          this.state.currentStep
        }
        dimensions = {
          this.state.dimensions
        }
        prodType = {
          this.state.prodType
        }
        /> 
      );
    }

    return ( 
      <Fragment>
        <div className = "nex_main-cont p-md-3 position-relative d-flex flex-column" >
          {
            renderProgressBar()
          }
          <Dimensions currentStep = {
              this.state.currentStep
            }
            wastage = {
              this.state.wastage
            }
            areaCalc = {
              this.totalAreaCalc
            }
            nextStep = {
              this._nextAdvanced
            }
            prevStep = {
              this._prevAdvanced
            }
            setWastage = {
              this.setWastage
            }
            lastStep = {
              this.state.lastStep
            }
            prodType = {
              this.state.prodType
            }
            subprodType = {
              this.state.subprodType
            }
          />


          <Materials currentStep = {
              this.state.currentStep
            }
            nextStep = {
              this._nextAdvanced
            }
            prevStep = {
              this._prevAdvanced
            }
            calcTotal = {
              this.calcTotalCostAdv
            }
            lastStep = {
              this.state.lastStep
            }
            currentProduct = {
              this.state.currentProduct
            }
            prodType = {
              this.state.prodType
            }
            subprodType = {
              this.state.subprodType
            }
          />
          <Viewport
            currentStep = {
              this.state.currentStep
            }
            nextStep = {
              this._nextAdvanced
            }
            prevStep = {
              this._prevAdvanced
            }
            lastStep = {
              this.state.lastStep
            }
            profile = {
              this.state.profile
            }
            prodType = {
              this.state.prodType
            }
            subprodType = {
              this.state.subprodType
            }
          />
          <MultistepForm currentStep = {
              this.state.currentStep
            }
            nextStep = {
              this._nextAdvanced
            }
            prevStep = {
              this._prevAdvanced
            }
            lastStep = {
              this.state.lastStep
            }
            formScore = {
              this.state.formScore
            }
	    updateFormScore = {
              this.updateFormScore
            }
            answers = {
              this.state.answers
            }
	    submitData = {
              this.submitData
            }
          />

          <Estimate currentStep = {
              this.state.currentStep
            }
            dimensions = {
              this.state.dimensions
            }
            area = {
              this.state.totalArea
            }
            wastage = {
              this.state.wastage
            }
            jumpStep = {
              this._jumpStep
            }
            totalMatCost = {
              this.state.totalMatCost
            }
            C = {
              this.state.C
            }
            totalMaintCost = {
              this.state.totalMaintCost
            }
            totalMerbauMaintCost = {
              this.state.totalMerbauMaintCost
            }
            totalAltCost = {
              this.state.totalAltCost
            }
            profile = {
              this.state.profile
            }
            handleExpandChange = {
              this.handleExpandChange
            }
            isExpanded = {
              this.state.isExpanded
            }
            totalMaintAltCost = {
              this.state.totalMaintAltCost
            }
            lastStep = {
              this.state.lastStep
            }
            answers = {
              this.state.answers
            }
            formScore = {
              this.state.formScore
            }
            discussProject = {
              this.discussProject
            }
            prodType = {
              this.state.prodType
            }
            subprodType = {
              this.state.subprodType
            }
          />
        </div>
      </Fragment>
    );
  }
}
export default DeckingEstimator;
