import React, { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

// Import function helper of paginate
import { getPaginatedProduct, getPageNumbers } from "./paginateFunc";

const swapBgImg = "https://www.futurewood.com.au/wp-content/uploads/2023/07/Xtreme-Img-2.jpg";
const swapBgBoard = "https://www.futurewood.com.au/wp-content/uploads/2023/07/swatch-eg.jpg"

const xOffset = 200;
const swatchVariants = {
  initial: (direction) => ({ x: direction > 0 ? xOffset : -xOffset }),
  animate: { x: 0 },
  exit: (direction) => ({ x: direction > 0 ? -xOffset : xOffset }),
  transition: {
    type: "spring",
    stiffness: 100,
    duration: 0.7,
    delay: 0.5,
    ease: [0, 0.71, 0.2, 1.01],
  },
};

const Swatch = ({
  currentProduct,
  colourChange,
  changeViewportStatus,
  currentSlide,
  activeColour,
  productChanged,
  setProductChanged,
}) => {
  const perPage = 8;
  const [currentSwatchPage, setCurrentSwatchPage] = useState(1);
  const [direction, setDirection] = useState(0);
  const [renderObj, setRenderObj] = useState(true);

  const handleColourChange = (event) => {
    event.preventDefault();
    colourChange(event.target.attributes.getNamedItem("data-value").value);
  };

  const handleViewportSwap = () => {
    let viewportStatus =
      currentSlide === activeColour.imageURLs.length - 1 ? "situ" : "board";

    changeViewportStatus(viewportStatus);
  };

  const handleChangePageNext = () => {
    setDirection(0);
    setCurrentSwatchPage((prevState) => prevState + 1);
    setRenderObj(false);
    setTimeout(handleRenderObjActivate(), 10000);
  };

  const handleChangePagePrevious = () => {
    setDirection(1);
    setCurrentSwatchPage((prevState) => prevState - 1);
    setRenderObj(false);
    setTimeout(handleRenderObjActivate(), 10000);
  };

  // This renderobj will update the animation for need a closer look and load more colors div
  const handleRenderObjActivate = () => {
    setRenderObj(true);
  };

  const productFunc = () => {
    switch (currentProduct.productName) {
      case "Xtreme Decking":
        return "xtreme";
      case "Original Decking":
        return "original";
      case "Original Decking Custom Colours":
        return "original";
      case "Eco-Pro Decking":
        return "ecopro";
      case "Eco-Pro Decking Custom Colours":
        return "ecopro";
      case "Cladding":
        return "cladding";
      case "Cladding Custom Colours":
        return "cladding";
      case "Screen Fencing":
        return "screening";
      case "Screen Fencing Custom Colours":
        return "screening";
      default:
        return " ";
    }
  };

  const swatchStatus = (colour) => {
    let status;
    if (activeColour.name === colour.name) status = "selected-colour";
    return status;
  };

  const handleSwatchHoverIn = (event) => {
    event.target.dataset.overlay = "show";
  };

  const handleSwatchHoverOut = (event) => {
    event.target.dataset.overlay = "hidden";
  };

  // Get current products
  const currentProductSliced = getPaginatedProduct(
    currentSwatchPage,
    perPage,
    currentProduct
  );

  // Get Page Numbers
  let pageNumbers = getPageNumbers(currentProduct, perPage);

  // Get First Page Number
  const firstPageNumber = pageNumbers[0];

  // Get Last Page number
  const lastPageNumber = pageNumbers[pageNumbers.length - 1];

  // Resets the current page
  useEffect(() => {
    if (productChanged) {
      setCurrentSwatchPage(1);
      setProductChanged(false);
    }
  }, [productChanged, setProductChanged]);

  return (
    <>
      <React.Fragment>
        <div className="d-flex p-0" style={{position: 'relative'}}>
          <div // eslint-disable-line jsx-a11y/no-static-element-interactions
            className="px-2 swatch-nav"
            style={{
              border: 0,
              alignSelf: "center",
              display: "flex",
              justifyContent: "start",
              cursor: "pointer",
              maxWidth: 60,
              position: "absolute",
              left: 0,
              opacity: 0.7,
              zIndex: 2,
            }}
            onClick={handleChangePagePrevious}
            onKeyDown={handleChangePagePrevious}
          >
            {currentSwatchPage > firstPageNumber && (
              <FaAngleLeft style={{ fontSize: 80, color: "#fff" }} />
            )}
          </div>
          {/* <div className="nex_scroll-up"></div> */}
          <div className={"w-100 nex_colorpicker " + productFunc()} style={{zIndex: 1}}>
            {currentProductSliced.map((colour) => (
              <>
                <motion.div
                  layout
                  className={
                    "nex_swatch position-relative " + swatchStatus(colour)
                  }
                  style={{ backgroundImage: `url(${colour.swatchURL})` }}
                  data-value={colour.name}
                  data-overlay="hidden"
                  key={colour.id}
                  onClick={handleColourChange}
                  onMouseEnter={handleSwatchHoverIn}
                  onMouseLeave={handleSwatchHoverOut}
                  variants={swatchVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition="transition"
                  custom={direction}
                >
                  <div className="nex_swatch-overlay position-absolute top-0 left-0 h-100 w-100" key={colour.id}>
                    <span className="nex_overlay-colour mb-1">
                      {colour.fullName}
                    </span>
                    <span className="nex_overlay-finish">
                      {colour.fullName === "Desert Oak" ||
                      colour.fullName === "Rustic Merbau" ||
                      colour.fullName === "Graphite" ||
                      colour.fullName === "Basalt" ||
                      colour.fullName === "Ash Grey" ||
                      colour.fullName === "Riverstone Grey" ||
                      colour.fullName === "Light Oak" ||
                      colour.fullName === "Ash Grey 2" ||
                      colour.fullName === "Riverstone Grey 2"
                        ? "Fine Grain Pattern"
                        : "Coarse Sanded Finish"}
                    </span>
                  </div>
                </motion.div>
              </>
            ))}
            {(renderObj && currentProduct.productName !== 'Cladding')&& (
              <AnimatePresence>
                <motion.div
                  layout
                  className="nex_swatchx2 position-relative"
                  onClick={handleViewportSwap}
                  style={{
                    backgroundImage: `url( ${
                      currentSlide === activeColour.imageURLs.length - 1
                        ? swapBgImg
                        : swapBgBoard
                    } )`,
                  }}
                  variants={swatchVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition="transition"
                >
                  <span // eslint-disable-line jsx-a11y/no-static-element-interactions
                    className="nex_swatch-overlay position-absolute top-0 left-0 h-100 w-100"
                    onClick={handleViewportSwap}
                    onKeyDown={handleViewportSwap}
                  >
                    {currentSlide === activeColour.imageURLs.length - 1
                      ? "Image View"
                      : "Need A Closer Look?"}
                  </span>
                </motion.div>
              </AnimatePresence>
            )}
            {renderObj &&
              currentProduct.colours.length > 6 &&
              currentSwatchPage < lastPageNumber &&
              currentProduct.colours.length > perPage && (
                <AnimatePresence>
                  <motion.div
                    layout
                    className="nex_swatchx3 position-relative"
                    onClick={handleChangePageNext}
                    style={{
                      backgroundColor: "#fff",
                    }}
                    variants={swatchVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition="transition"
                  >
                    <span className="nex_swatch-overlay position-absolute top-0 left-0 h-100 w-100">
                      Load More Colors &nbsp; &gt;
                    </span>
                  </motion.div>
                </AnimatePresence>
              )}
          </div>
          <div // eslint-disable-line jsx-a11y/no-static-element-interactions
            className="px-2 swatch-nav"
            style={{
              border: 0,
              alignSelf: "center",
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
              maxWidth: 60,
              position: "absolute",
              right: 0,
              opacity: 0.7,
              zIndex: 2,
            }}
            onClick={handleChangePageNext}
            onKeyDown={handleChangePageNext}
          >
            {currentSwatchPage < lastPageNumber && (
              <FaAngleRight style={{ fontSize: 80, color: "#fff" }} />
            )}
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default Swatch;
